<template>
  <div :class="colSize" class="row q-py-sm">
    <div class="col-12 q-px-sm text-h5">Persoonsgegevens</div>
    <div class="q-px-sm" :class="showFirstName ? 'col-4' : 'col-6'">
      <q-select
        :readonly="readonly || prospectInformationBlock"
        dense
        outlined
        v-model="gender"
        :options="genderOptions"
        :rules="prospectInformationBlock ? [] : validate('gender')"
        emit-value
        map-options
        label="Aanhef"
      />
    </div>
    <div class="q-px-sm" :class="showFirstName ? 'col-4' : 'col-6'">
      <q-input
        :readonly="readonly || prospectInformationBlock"
        dense
        outlined
        :modelValue="initials"
        :rules="prospectInformationBlock ? [] : validate('initials')"
        @change="initials = $event"
        label="Voorletters"
      />
    </div>
    <div class="col-4 q-px-sm" v-if="showFirstName">
      <q-input
        :readonly="readonly || prospectInformationBlock"
        dense
        outlined
        :modelValue="first_name"
        @change="first_name = $event"
        label="Voornaam"
      />
    </div>
    <div class="q-px-sm" :class="showCustomerNumber ? 'col-4' : 'col-6'">
      <q-input
        :readonly="readonly || prospectInformationBlock"
        dense
        outlined
        :modelValue="affix"
        @change="affix = $event"
        :rules="prospectInformationBlock ? [] : validate('lowerCase')"
        label="Tussenvoegsel"
      />
    </div>
    <div class="q-px-sm" :class="showCustomerNumber ? 'col-4' : 'col-6'">
      <q-input
        :readonly="readonly || prospectInformationBlock"
        dense
        outlined
        :modelValue="last_name"
        @change="last_name = $event"
        :rules="prospectInformationBlock ? [] : validate('required')"
        label="Achternaam"
      />
    </div>
    <div class="col-4 q-px-sm" v-if="showCustomerNumber">
      <q-input
        readonly
        dense
        outlined
        :modelValue="customerNumber"
        @change="customerNumber = $event"
        :rules="prospectInformationBlock ? [] : validate('required')"
        label="Essent klantnummer"
      />
    </div>
  </div>
</template>

<script>
import { computed, toRef } from "vue";
import { useQuasar } from "quasar";
import { useStore } from "vuex";
import { validate, validator } from "../../plugins/validation.js";

export default {
  props: {
    settings: Object,
  },
  setup(props) {
    const $q = useQuasar();
    const $store = useStore();
    // eslint-disable-next-line no-undef
    const component_settings = toRef(props, "settings");

    const genderOptions = [
      {
        label: "Mevrouw",
        value: "V",
      },
      {
        label: "De heer",
        value: "M",
      },
    ];

    const loading = computed({
      get() {
        return $store.getters.loading ?? false;
      },
      set(newVal) {
        $store.dispatch("setLoading", {
          key: newVal[0],
          value: newVal[1],
        });
      },
    });

    const gender = computed({
      get() {
        return $store.getters.record?.Geslacht;
      },
      set(newVal) {
        $store.dispatch("updateRecord", { key: "Geslacht", value: newVal });
      },
    });

    const initials = computed({
      get() {
        return $store.getters.record?.Initialen ?? "";
      },
      set(newVal) {
        if (!prospectInformationBlock.value) {
          newVal = newVal.replace(/\./g, "").toUpperCase().split("").join(".");
          if (newVal.length > 0) {
            newVal = newVal + ".";
          }
        }
        $store.dispatch("updateRecord", { key: "Initialen", value: newVal });
      },
    });

    const first_name = computed({
      get() {
        return $store.getters.record?.Voornaam ?? "";
      },
      set(newVal) {
        $store.dispatch("updateRecord", { key: "Voornaam", value: newVal });
      },
    });

    const affix = computed({
      get() {
        return $store.getters.record?.Tussenvoegsel ?? "";
      },
      set(newVal) {
        $store.dispatch("updateRecord", {
          key: "Tussenvoegsel",
          value: newVal,
          validated: validator("lowerCase", newVal),
        });
      },
    });

    const last_name = computed({
      get() {
        return $store.getters.record?.Achternaam ?? "";
      },
      set(newVal) {
        $store.dispatch("updateRecord", {
          key: "Achternaam",
          value: newVal,
          validated: validator("required", newVal),
        });
      },
    });
    const customerNumber = computed({
      get() {
        return $store.getters.projectSpecific?.EssentKlantnummer ?? "";
      },
      set(newVal) {
        $store.dispatch("setProjectSpecificValues", {
          values: {
            EssentKlantnummer: newVal,
          },
        });
      },
    });

    const readonly = computed(
      () => ($store.getters.record?.locked || $store.getters.viewMode) ?? false
    );

    const prospectInformationBlock = computed(() =>
      $store.getters.view == "retention" ? true : false
    );

    if (initials.value != "") {
      const initialProxy = initials.value;
      initials.value = initialProxy;
    }

    const showCustomerNumber = computed(
      () => component_settings.value?.customerNumber?.enabled ?? false
    );
    const showFirstName = computed(
      () => component_settings.value?.firstName?.enabled ?? true
    );

    return {
      colSize: computed(() => {
        return $q.screen.lt.md ? "col-12" : "col-6";
      }),
      readonly,
      prospectInformationBlock,
      genderOptions,
      loading,
      gender,
      initials,
      first_name,
      affix,
      last_name,
      customerNumber,
      component_settings,
      showCustomerNumber,
      showFirstName,
    };
  },
  methods: {
    validate,
  },
};
</script>
